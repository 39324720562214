@import 'constants';
@import 'mixins';

$module: 'LocationsMenuNavItem';

.#{$module} {
  &__current {
    max-width: 300px;

    @include truncate();
  }

  &__add-location,
  &__location {
    a[class^='heap--navbar'] {
      color: var(--primary700);

      &:hover {
        color: $accent900;
      }
    }
  }
}
